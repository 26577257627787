import PropTypes from "prop-types";
import styles from "./Feature.module.css";

const Feature = ({ featureTitle, featureDescription, asset, type, featureicon }) => {
  return (
    <div className={`${styles.featureitemsbcg}`}>
      <div className={`${styles.container}`}>
        <div
          className={`${styles.contmargin} flex align-item-center space-between flex-wrap-reverse`}
        >
          <div className={`${styles.featureinfo}`}>
            <img src={featureicon} alt="feature Icon" />
            <h4>{featureTitle}</h4>
            <p>{featureDescription}</p>
          </div>
          {type === "video" ? (
            <div className={`${styles.featureasset}`}>
              <video width="600" controls loop autoPlay muted>
                <source src={asset} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

          ) :
            <div className={`${styles.featureimage}`}>
              <img src={asset} alt="features image" width="100%" />
            </div>}
        </div>
      </div>
    </div>
  );
};
Feature.PropTypes = {
  featureTitle: PropTypes.string,
  featureDescription: PropTypes.string,
  image: PropTypes.any,
  featureicon: PropTypes.any
};

export default Feature;
