import styles from "./Footer.module.css";
import { Grid } from "@mui/material";
import Logo from "../../assets/images/whitelogo.png";
import AppStoreImg from "../../assets/images/appstore.svg";
import GoogleStoreImg from "../../assets/images/googleplay.svg";
import { Link } from "react-router-dom";

const index = () => {
  return (
    <div className={`${styles.footerfirstbcg}`}>
      <div className={`${styles.footersecondbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.firstrow}`}>
              <Grid container spacing={2}>
                <Grid className={`${styles.links}`} item lg={3} md={3} sm={3} xs={4}>
                  <Link>Home</Link>
                  <Link>Guide</Link>
                  <Link to="/release-note">Release Notes</Link>
                  <Link>Features</Link>
                  <Link>Pricing</Link>
                </Grid>
                <Grid className={`${styles.links}`} item lg={2} md={2} sm={2} xs={4}>
                  <Link>About Us</Link>
                  <Link>FAQ</Link>
                  <Link>Press</Link>
                  <Link to="/privacy-policy">Privacy and Policy</Link>
                  <Link>Terms of Service</Link>
                </Grid>
                <Grid className={`${styles.links}`} item lg={2} md={2} sm={2} xs={4}>
                  <Link>Pricing</Link>
                  <Link></Link>
                </Grid>
                <Grid className={`${styles.links}`} item lg={3} md={2} sm={2} xs={4}>
                  <Link>Solutions</Link>
                  <Link>University 21</Link>
                  <Link>Feature Request</Link>
                  <Link>Custom Solutions</Link>
                </Grid>
                <Grid className={`${styles.links}`} item lg={2} md={2} sm={2} xs={4}>
                  <Link>Developers</Link>
                  <Link>Get Started</Link>
                  <Link>Documentation</Link>
                  <Link>API References</Link>
                  <Link>API Status</Link>
                </Grid>
              </Grid>
            </div>
            <hr />
            <div className={`${styles.secondrow}`}>
              <Grid container spacing={2}>
                <Grid className={`${styles.links}`} item lg={3} md={3} sm={6} xs={6}>
                  <Link>Contact</Link>
                  <Link>Support@shelf21.com</Link>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <Link>United Kingdom</Link>
                  <p>
                    C/O Aacsl Accountants Ltd, 1st Floor, North Westgate House,
                    Harlow, Essex, United Kingdom, CM20 1YS
                  </p>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <Link>Nigeria</Link>
                  <p>
                    Somewhere off the Coast of Lagos. 24 Avenue, 24 Street.
                    Lagos, Nigeria
                  </p>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <Link>United Kingdom</Link>
                  <p>
                    C/O Aacsl Accountants Ltd, 1st Floor, North Westgate House,
                    Harlow, Essex, United Kingdom, CM20 1YS
                  </p>
                </Grid>
              </Grid>
            </div>
            {/* fOOTER THIRD ROW */}
            <div className={`${styles.thirdrow}`}>
              <hr />
              <p className="margin-top-40 font-size-14">
                C/O Aacsl Accountants Ltd, 1st Floor, North Westgate House,
                Harlow, Essex, United Kingdom, CM20 1YS Manage all your
                resources on our robust back office, dedicated to provide you al
                you need to make the most of your operations. From dashboards,
                product management to reporting, you never miss a beat. Manage
                all your resources on our robust back office, dedicated to
                provide you al you need to make the most of your operations.
                From dashboards, product management to reporting, you never miss
                a beat.
              </p>
            </div>
            {/* Mobile View */}
            <div className={`${styles.mobilefooter}`}>
              <div className={`${styles.footerimg}`}>
                <img src={Logo} alt="Shelf Logo" />
              </div>
              <p>
                {" "}
                Let us unleash our creativity and expertise to create designs
                that deliver extraordinary results.
              </p>
              <div className={`${styles.onlinestores}`}>
                <img src={AppStoreImg} alt="App Store" />
                <img src={GoogleStoreImg} alt="Google Store" />
              </div>
              <small>© 2023 Shelf21. All rights reserved.</small>
              <div className={`${styles.footerlinks}`}>
                <Link to="">
                  Home
                </Link>
                <Link to="">How it works</Link>
                <Link to="/feature">Features</Link>
                <Link to="">Contact Us</Link>
                <Link className="margin-top-32" to="">Help Centre</Link>
                <Link to="">FAQ</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/privacy-policy">Terms of Service</Link>
                <Link className="margin-top-32" to="/pricing">Pricing</Link>
                <Link to="/release-note">Release Notes</Link>
                {/* <p>Home</p>
                <p>How it works</p>
                <p>Features</p>
                <p>Contact Us</p>
                <p className="margin-top-32">Help Centre</p>
                <p>FAQ</p>
                <p>Privacy Policy</p>
                <p>Terms of Service</p>
                <p className="margin-top-32">Pricing</p>
                <p>Release Notes</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
